import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    StackProps
} from '@mui/material'
import {
    CoreTypography,
    LeafConfirmationDialog
} from '@thriveglobal/thrive-web-leafkit'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    AssessmentAttemptSuccess,
    AssessmentItem,
    ResponseInput,
    ResponseOptionCms,
    useSubmitResponseMutation
} from '../../../graphql/generated/autogenerated'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import PrivacyNotice from '../../../components/PrivacyNotice'

interface BaselineAssessmentItemProps extends StackProps {
    step: number
    assessmentItem: AssessmentItem
    prevStep: () => void
    nextStep: () => void
    assessmentAttempt: AssessmentAttemptSuccess
    setLatestAssessmentResponse: (AssessmentAttemptSuccess) => void
    skipConfirmationDialog: boolean
}

const messages = defineMessages({
    backButtonLabel: {
        defaultMessage: 'Back',
        description: 'Button label for going back to the previous page.'
    },
    nextButtonLabel: {
        defaultMessage: 'Next',
        description: 'Button label for proceeding to the next page.'
    },
    confirmationDialogTitle: {
        defaultMessage: 'Are you sure?',
        description:
            "Check if user is sure they'd like to continue with submitting their survey responses."
    },
    confirmationDialogContent: {
        defaultMessage:
            'Once you submit your responses you won’t be able to change them.',
        description:
            "Notice to users that they won't be able to change their answers once they continue."
    },
    confirmationDialogConfirmation: {
        defaultMessage: 'Confirm',
        description: 'Confirmation to continue'
    }
})

const BaselineAssessmentItem: React.FC<BaselineAssessmentItemProps> = ({
    step,
    assessmentItem,
    prevStep,
    nextStep,
    assessmentAttempt,
    setLatestAssessmentResponse,
    skipConfirmationDialog,
    ...stackProps
}) => {
    const intl = useIntl()
    const [submitResponseMutation, { data, loading, error }] =
        useSubmitResponseMutation()

    function responseOptionOrderFn(a: ResponseOptionCms, b: ResponseOptionCms) {
        if (a.displayOrder < b.displayOrder) {
            return -1
        } else if (a.displayOrder > b.displayOrder) {
            return 1
        }
        return 0
    }

    const responseOptions = assessmentItem.responses.sort(responseOptionOrderFn)

    const [selectedResponse, setSelectedResponse] =
        useState<ResponseInput>(undefined)

    function handleBackClicked() {
        prevStep()
    }

    function submitAssessmentResponse(onCompleted?: () => void) {
        const response = submitResponseMutation({
            variables: {
                input: {
                    assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
                    assessmentItemId: assessmentItem.id,
                    questionText: assessmentItem.questionText,
                    response: selectedResponse
                }
            },
            onCompleted: (data) => {
                if (onCompleted) {
                    onCompleted()
                }
            }
        })

        response.then((r) => {
            Avo.surveySubmitted({
                featureType: 'assessment',
                activityType: 'onboarding_response_submitted',
                assessmentId: assessmentAttempt.assessmentId,
                assessmentItemId: assessmentItem.id,
                assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
                assessmentName: assessmentAttempt.name,
                assessmentVariant: assessmentAttempt.variant,
                isOnboarding: true,
                responseText: selectedResponse
                    ? selectedResponse.text
                    : undefined,
                responseValue: selectedResponse
                    ? selectedResponse.value
                    : undefined,
                dciCount: undefined,
                journey: undefined,
                journeyDay: undefined,
                journeyId: undefined,
                journeyLevel: undefined,
                npsScore: undefined,
                openAnswer: undefined,
                firstJourney: undefined,
                tabName: undefined,
                surveyLocation: undefined,
                messageQueueId: undefined,
                dispatchedDate: undefined
            })
            if (
                r.data?.assessment?.submitResponse?.__typename ===
                'AssessmentAttemptSuccess'
            ) {
                setLatestAssessmentResponse(r.data.assessment.submitResponse)
            }
        })
    }

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState<boolean>(false)
    const closeConfirmationDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    const confirmConfirmationDialog = () => {
        submitAssessmentResponse(nextStep)
        closeConfirmationDialog()
    }

    function handleNextClicked() {
        const finalStep = step === assessmentAttempt.allQuestions.length
        if (finalStep) {
            // complete assessment, wait for last response submitted before closing
            if (skipConfirmationDialog) {
                submitAssessmentResponse(nextStep) // won't progress until final answer is submitted
            } else {
                setIsConfirmationDialogOpen(true)
            }
        } else {
            submitAssessmentResponse()
            nextStep()
        }
    }

    const handleOptionClicked = (e: any, option: ResponseOptionCms) => {
        setSelectedResponse({
            text: option.text,
            value: option.value,
            normalizedValue: option.normalizedValue
        })
        // option to auto-progress user
        // if(e.type === "mouseup") {
        //     setTimeout(handleNextClicked, 100)
        // }
    }

    return (
        <Stack display={'flex'} gap={2} alignItems={'center'} {...stackProps}>
            <FormControl component={Card} fullWidth sx={{ maxWidth: '600px' }}>
                <CardContent>
                    <FormLabel id="baseline-assessment-label">
                        <CoreTypography
                            variant={'h3'}
                            color={'text.primary'}
                            textAlign={'center'}
                            mb={2}
                        >
                            {assessmentItem.questionText}
                        </CoreTypography>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="baseline-assessment-label"
                        name={'baseline-assessment-form-' + assessmentItem.id}
                    >
                        {responseOptions.map((option, i) => (
                            <FormControlLabel
                                key={assessmentItem.id + ' response ' + i}
                                id={assessmentItem.id + ' response ' + i}
                                value={option.value}
                                // onMouseUp={(e) => handleOptionClicked(e, option)}
                                onClick={(e) => handleOptionClicked(e, option)}
                                control={<Radio tabIndex={i === 0 ? 0 : -1} />}
                                label={
                                    <CoreTypography
                                        customVariant={'buttonNormal'}
                                        width={'fit-content'}
                                    >
                                        {option.text}
                                    </CoreTypography>
                                }
                                aria-checked={
                                    option.value === selectedResponse?.value
                                }
                                sx={{
                                    ml: 1,
                                    my: 1,
                                    borderRadius: '8px',
                                    backgroundColor:
                                        option.value === selectedResponse?.value
                                            ? 'secondary.main'
                                            : 'grey.100',
                                    color:
                                        option.value === selectedResponse?.value
                                            ? 'secondary.contrastText'
                                            : 'text.primary'
                                }}
                            />
                        ))}
                    </RadioGroup>
                </CardContent>
            </FormControl>
            <Stack
                direction={'row'}
                justifyContent={'center'}
                gap={1}
                width={'100%'}
            >
                {step !== 1 && (
                    <Button onClick={handleBackClicked}>
                        <CoreTypography customVariant={'buttonNormal'}>
                            {intl.formatMessage(messages.backButtonLabel)}
                        </CoreTypography>
                    </Button>
                )}
                <Button variant={'contained'} onClick={handleNextClicked}>
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.nextButtonLabel)}
                    </CoreTypography>
                </Button>
                {!skipConfirmationDialog && (
                    <LeafConfirmationDialog
                        dialogTitle={
                            <CoreTypography variant={'h3'}>
                                {intl.formatMessage(
                                    messages.confirmationDialogTitle
                                )}
                            </CoreTypography>
                        }
                        dialogContent={
                            <CoreTypography variant={'body1'}>
                                {intl.formatMessage(
                                    messages.confirmationDialogContent
                                )}
                            </CoreTypography>
                        }
                        primaryButtonText={intl.formatMessage(
                            messages.confirmationDialogConfirmation
                        )}
                        primaryButtonOnClick={confirmConfirmationDialog}
                        secondaryButtonText={intl.formatMessage(
                            messages.backButtonLabel
                        )}
                        secondaryButtonOnClick={closeConfirmationDialog}
                        onClose={closeConfirmationDialog}
                        open={isConfirmationDialogOpen}
                    />
                )}
            </Stack>
            <PrivacyNotice />
        </Stack>
    )
}

export default BaselineAssessmentItem
