import { FC, useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    CardProps,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import BaselineAssessmentDialog from '../BaselineAssessmentDialog'
import { defineMessages, useIntl } from 'react-intl'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import { useTryGetLatestAttempt } from '../../../hooks/useTryGetLatestAttempt'

const messages = defineMessages({
    baselineAssessmentCTATitle: {
        defaultMessage: 'Help us support your Intention',
        description:
            "Title for a call to action to support a user's intention/goal."
    },
    baselineAssessmentCTADescription: {
        defaultMessage:
            'Everyone’s path towards their Intention is unique. Help us know where you are by answering a series of short questions.',
        description:
            'Sub-title telling the user about the outcomes of taking a short survey and the duration of the survey.'
    },
    learnMoreButtonLabel: {
        defaultMessage: 'Learn more',
        description:
            'Button label prompting the user to learn more about the assessment.'
    },
    takeAssessmentButtonLabel: {
        defaultMessage: 'Start assessment',
        description: 'Button label prompting the user to take their assessment.'
    }
})

const IMAGE_URL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/7fa5fd82-447d-40cf-449e-ccbdf5744e00/cms'
const IMAGE_HEIGHT = '259px'

interface BaselineAssessmentCTAProps {
    onClose?: () => void
    hasSetIntention?: boolean
}

const BaselineAssessmentCTA: React.FC<BaselineAssessmentCTAProps> = ({
    onClose = () => {},
    hasSetIntention = true
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const isSmallScreen = useIsSmallScreen()
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [baselineAssessmentDialogOpen, setBaselineAssessmentDialogOpen] =
        useState<boolean>(false)
    const openBaselineAssessmentDialog = () => {
        setBaselineAssessmentDialogOpen(true)
    }
    const closeBaselineAssessmentDialog = () => {
        onClose()
        setBaselineAssessmentDialogOpen(false)
    }

    return (
        <>
            <Stack
                direction={isSmallScreen ? 'column' : 'row'}
                gap={isSmallScreen ? 2 : 4}
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <img height={IMAGE_HEIGHT} src={IMAGE_URL} alt={''} />
                <Stack gap={2} maxWidth={'480px'}>
                    <CoreTypography variant={'h2'}>
                        {intl.formatMessage(
                            messages.baselineAssessmentCTATitle
                        )}
                    </CoreTypography>
                    <CoreTypography variant={'body1'}>
                        {intl.formatMessage(
                            messages.baselineAssessmentCTADescription
                        )}
                    </CoreTypography>
                    <Stack
                        direction={isMediumScreen ? 'column' : 'row'}
                        gap={3}
                    >
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth={isMediumScreen}
                            onClick={openBaselineAssessmentDialog}
                        >
                            <CoreTypography customVariant={'buttonNormal'}>
                                {intl.formatMessage(
                                    messages.takeAssessmentButtonLabel
                                )}
                            </CoreTypography>
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <BaselineAssessmentDialog
                open={baselineAssessmentDialogOpen}
                onClose={closeBaselineAssessmentDialog}
            />
        </>
    )
}

export default BaselineAssessmentCTA
